<template>
  <div>
    <base-nav
      :class="{'navbar-dark': type === 'default'}"
      container-classes="container-fluid"
      class="bg-white navbar-top border-bottom navbar-expand"
    >

      <div class="header_logo position-relative">
        <!--      <span class="h2_text h2 align-middle d-none d-lg-block">{{ name }}</span>-->
      </div>

      <!-- logo -->
      <div class="header_logo position-relative">
        <template v-if="currentContext === 'clientContext'">
          <div class="animated fadeInLeft faster">
            <nuxt-link @click.native="clearContext" to="/khach-hang" v-show="routeName === 'khach_hang.tong_quan'">
              <span class="animated fadeIn align-middle mr-2"><i class="fa fa-chevron-left"></i></span>
            </nuxt-link>
            <span class="h2_text h2 text-danger align-middle">{{ client.company_abbreviation ? client.company_abbreviation : client.company_name}}</span>
            <nuxt-link :to="{name: 'khach_hang.tong_quan', params: {id: client.id}}"
                       v-if="routeName !== 'khach_hang.tong_quan'">
              <base-button class="ml-3 animated fadeInDown faster" icon size="sm" type="neutral">
                <span class=""><i class="fa fa-list"></i></span>
                <span v-text="$t('overview')">{{ $t('overview') }}</span>
              </base-button>
            </nuxt-link>
          </div>
        </template>
      </div>
      <!-- Navbar links -->
      <div class="menu-right position-md-absolute d-flex align-items-center">
        <ul class="navbar-nav">
          <li class="nav-item d-xl-none">
            <!-- Sidenav toggler -->
            <div
              :class="{active: $sidebar.showSidebar, 'sidenav-toggler-dark': type === 'default', 'sidenav-toggler-light': type === 'light'}"
              @click="toggleSidebar"
              class="pr-3 pt-2 sidenav-toggler"
            >
              <div class="sidenav-toggler-inner text-dark">
                <i class="sidenav-toggler-line"/>
                <i class="sidenav-toggler-line"/>
                <i class="sidenav-toggler-line"/>
              </div>
            </div>
          </li>
          <base-dropdown class="nav-item"
                         tag="li"
                         title-classes="nav-link text-primary"
                         title-tag="a"
                         icon="ni ni-bell-55"
                         menu-classes="dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden"
                        :title="notificationCount > 0 ? (notificationCount + '') : ''"
            >
              <template v-if="notificationCount > 0" slot="title">
                <svg width="23" height="27" viewBox="0 0 23 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.45768 7C5.40759 7 2.12435 10.2832 2.12435 14.3333V20.3333H0.791016V21.6667H18.1243V20.3333H16.791V14.3333C16.791 10.2832 13.5078 7 9.45768 7Z" fill="#2DCE89" />
                  <path d="M6.12435 23.6667V23H12.791V23.6667C12.791 25.5076 11.2986 27 9.45768 27C7.61673 27 6.12435 25.5076 6.12435 23.6667Z" fill="#2DCE89" />
                  <g transform="translate(16.1606, 7.63257)">
                    <circle r="6.70312" fill="#DA2727"/>
                    <text style="font-size:60%; font-weight:lighter;" stroke="#FFF" text-anchor="middle" alignment-baseline="central">{{ notificationCount }}</text>
                  </g>
                </svg>
              </template>
              <template v-else slot="title">
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.4577 0C6.40759 0 3.12435 3.28325 3.12435 7.33333V13.3333H1.79102V14.6667H19.1243V13.3333H17.791V7.33333C17.791 3.28325 14.5078 0 10.4577 0Z" fill="#2DCE89" />
                  <path d="M7.12435 16.6667V16H13.791V16.6667C13.791 18.5076 12.2986 20 10.4577 20C8.61673 20 7.12435 18.5076 7.12435 16.6667Z" fill="#2DCE89" />
                </svg>
              </template>
              <template>
                <Notifications @newNotificationCount="updateNotificationCount($event)"/>
              </template>
          </base-dropdown>

        </ul>
      <ul class="navbar-nav d-inline-block">
        <li class="nav-item">
          <SwitchLocale/>
        </li>
      </ul>
        <ul class="navbar-nav d-inline-block">
          <base-dropdown menu-on-right
                         class="nav-item nav-item1"
                         tag="li"
                         title-tag="a"
                         title-classes="nav-link pr-0">
            <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
              <div class="media align-items-center d-flex">
                <!--<span class="rounded-circle">-->
                <!--<b-img v-if="sex == 'male'" src="/img/theme/man.png" width="30" height="30" rounded="circle" alt="Circle image"></b-img>-->
                <!--<b-img v-if="sex == 'female'" src="/img/theme/woman.png" width="30" height="30" rounded="circle" alt="Circle image"></b-img>-->
                <!--</span>-->
                <vue-avatar class="vue-avatar" :username='name' :size='31' :rounded="true"/>
                <div class="media-body ml-2 d-none d-lg-block">
                  <span class="mb-0 text-sm text-gray font-weight-bold" :title="name">{{ name }}</span>
                </div>
              </div>
            </a>

            <template>
              <a @click="showChangePassword" class="dropdown-item">
                <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.0498 0.510742H2.2998C1.80252 0.510742 1.32561 0.708286 0.97398 1.05992C0.622349 1.41155 0.424805 1.88846 0.424805 2.38574V11.1357C0.424805 11.633 0.622349 12.1099 0.97398 12.4616C1.32561 12.8132 1.80252 13.0107 2.2998 13.0107H11.0498C11.5471 13.0107 12.024 12.8132 12.3756 12.4616C12.7273 12.1099 12.9248 11.633 12.9248 11.1357V2.38574C12.9248 1.88846 12.7273 1.41155 12.3756 1.05992C12.024 0.708286 11.5471 0.510742 11.0498 0.510742ZM11.6748 11.1357C11.6748 11.3015 11.609 11.4605 11.4917 11.5777C11.3745 11.6949 11.2156 11.7607 11.0498 11.7607H2.2998C2.13404 11.7607 1.97507 11.6949 1.85786 11.5777C1.74065 11.4605 1.6748 11.3015 1.6748 11.1357V2.38574C1.6748 2.21998 1.74065 2.06101 1.85786 1.9438C1.97507 1.82659 2.13404 1.76074 2.2998 1.76074H11.0498C11.2156 1.76074 11.3745 1.82659 11.4917 1.9438C11.609 2.06101 11.6748 2.21998 11.6748 2.38574V11.1357ZM6.6748 4.26074C6.34328 4.26074 6.02534 4.39244 5.79092 4.62686C5.5565 4.86128 5.4248 5.17922 5.4248 5.51074C5.42612 5.72888 5.4845 5.94288 5.59415 6.13147C5.70379 6.32006 5.86088 6.47667 6.0498 6.58574V8.63574C6.0498 8.8015 6.11565 8.96047 6.23286 9.07768C6.35007 9.19489 6.50904 9.26074 6.6748 9.26074C6.84057 9.26074 6.99954 9.19489 7.11675 9.07768C7.23396 8.96047 7.2998 8.8015 7.2998 8.63574V6.58574C7.48873 6.47667 7.64582 6.32006 7.75546 6.13147C7.86511 5.94288 7.92349 5.72888 7.9248 5.51074C7.9248 5.17922 7.79311 4.86128 7.55869 4.62686C7.32427 4.39244 7.00633 4.26074 6.6748 4.26074Z"
                    fill="#2DCE89"/>
                </svg>
                <span>{{ $t('change_password') }}</span>
              </a>
              <a class="dropdown-item" @click.prevent="logout" href="#">
                <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.81692 1.25027C1.22806 0.83913 1.78568 0.608154 2.36712 0.608154H9.29025C9.87168 0.608154 10.4293 0.839128 10.8404 1.25027C11.2516 1.66141 11.4826 2.21903 11.4826 2.80047V4.24279C11.4826 4.657 11.1468 4.99279 10.7326 4.99279C10.3184 4.99279 9.98256 4.657 9.98256 4.24279V2.80047C9.98256 2.61686 9.90962 2.44076 9.77979 2.31093C9.64996 2.18109 9.47386 2.10815 9.29025 2.10815H2.36712C2.18351 2.10815 2.00741 2.18109 1.87758 2.31093C1.74774 2.44076 1.6748 2.61686 1.6748 2.80047V11.4544C1.6748 11.638 1.74774 11.8141 1.87758 11.9439C2.00741 12.0738 2.18351 12.1467 2.36712 12.1467H9.29025C9.47386 12.1467 9.64996 12.0738 9.77979 11.9439C9.90962 11.8141 9.98256 11.638 9.98256 11.4544V10.0121C9.98256 9.59785 10.3184 9.26206 10.7326 9.26206C11.1468 9.26206 11.4826 9.59785 11.4826 10.0121V11.4544C11.4826 12.0358 11.2516 12.5934 10.8404 13.0046C10.4293 13.4157 9.87168 13.6467 9.29025 13.6467H2.36712C1.78568 13.6467 1.22806 13.4157 0.81692 13.0046C0.40578 12.5934 0.174805 12.0358 0.174805 11.4544V2.80047C0.174805 2.21903 0.40578 1.66141 0.81692 1.25027ZM12.5098 3.71268C12.8027 3.41978 13.2776 3.41978 13.5705 3.71268L16.4551 6.59731C16.748 6.89021 16.748 7.36508 16.4551 7.65797L13.5705 10.5426C13.2776 10.8355 12.8027 10.8355 12.5098 10.5426C12.2169 10.2497 12.2169 9.77484 12.5098 9.48195L14.1142 7.87761L6.08109 7.87747C5.66687 7.87746 5.33109 7.54167 5.3311 7.12746C5.33111 6.71324 5.6669 6.37746 6.08111 6.37747L14.1141 6.37761L12.5098 4.77334C12.2169 4.48045 12.2169 4.00557 12.5098 3.71268Z"
                        fill="#2DCE89"/>
                </svg>
                <span>{{$t('logout')}}</span>
              </a>

            </template>
          </base-dropdown>
        </ul>
      </div>
    </base-nav>

    <modal :show.sync="remindChangeRandomPasswordModal"
           modal-classes="modal-dialog-centered" size="lg"
           class="vpo-modal">
      <template slot="header">
        <h2 class="text-white">{{$t('change_password')}}</h2>
      </template>
      <div>
        <ValidationObserver ref="formData" v-slot="{ passes }">
          <form>
            <div class="position-relative c-shadow">
              <input alternative
                     ref="password" :label="$t('password')"
                     class="mb-3 form-control"
                     name="Password"
                     rules="required|min:6|max:35"
                     :type="passwordFieldType"
                     :placeholder="$t('login.enter_password')"
                     v-model="newPassword">
              </input>
              <button type="password" @click.prevent="switchVisibility" class="position-absolute btn btn-link"
                      style="top: 5px; right: 0"><i class="fas fa-eye"></i></button>
            </div>

            <!--            <VeeInput :label="$t('login.confirm_password')"-->
            <!--                        :placeholder="$t('login.enter_password')"-->
            <!--                        appendIcon="fa fa-lock"-->
            <!--                        type="password"-->
            <!--                        name="confirm_password"-->
            <!--                        v-model="confirmNewPassword"-->
            <!--                        rules="required|confirmed:password"-->
            <!--                        ></VeeInput>-->
            <div class="position-relative c-shadow">
              <input alternative
                     class="mb-3 form-control"
                     name="Password"
                     rules="required|confirmed:password"
                     :type="passwordFieldType1"
                     :placeholder="$t('login.enter_password')"
                     v-model="confirmNewPassword">
              </input>
              <button type="password" @click.prevent="switchVisibility" class="position-absolute btn btn-link"
                      style="top: 5px; right: 0"><i class="fas fa-eye"></i></button>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div class="text-center">
        <base-button type="primary" @click="onChangeRandomPassword" :loading="changingPassword"
                     :disabled="changingPassword" class="my-4 font-weight-normal font-size-16">{{
          $t('model.buttons.save')}}
        </base-button>
      </div>

    </modal>
    <SessionTimeout/>
  </div>
</template>
<script>
  import {CollapseTransition} from 'vue2-transitions'
  import {BaseNav, Modal} from '@/components'
  import {mapActions, mapGetters} from 'vuex'
  import VueAvatar from 'vue-avatar'
  import Notifications from '@/../frontend-common/components/notifications'
  import SwitchLocale from '@/../frontend-common/components/SwitchLocale'
  import Helper from '@/util/helper'
  import Swal from 'sweetalert2'
  import SessionTimeout from '@/../frontend-common/components/SessionTimeout'

  export default {
    components: {
      CollapseTransition,
      BaseNav,
      Modal,
      VueAvatar,
      SessionTimeout,
      Notifications,
      SwitchLocale
    },
    props: {
      type: {
        type: String,
        default: 'default', // default|light
        description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
      }
    },
    data() {
      const user = this.$store.getters['auth/user']
      return {
        name: user.name,
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        searchQuery: '',
        notifications: [],
        changingPassword: false,
        newPassword: '',
        confirmNewPassword: '',
        remindChangeRandomPasswordModal: user.changed_random_password == 0 ? true : false,
        passwordFieldType: 'password',
        passwordFieldType1: 'password',
        notificationCount: 0,
      }
    },
    mounted() {
      this.setSessionTimeout();
    },
    computed: {
      routeName() {
        const {name} = this.$route
        return name
      },

      /**
       * Vuex
       */
      ...mapGetters({
        currentContext: 'context/currentContext',
        client: 'context/client',
      })
    },
    methods: {
      logout() {

        this.$store.dispatch('auth/logout').then(() => {

          window.location.assign('/dang-nhap')
        })
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown() {
        this.activeNotifications = false
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      showChangePassword() {
        this.remindChangeRandomPasswordModal = true
        this.newPassword = ""
        this.confirmNewPassword = ""
      },
      onChangeRandomPassword() {

        this.$refs.formData.validate().then(async (success) => {

          if (!success) return;

          this.changingPassword = true

          Helper.gqlMutate('User', 'changeRandomPassword', {
            password: this.newPassword
          }).then((res) => {

            Swal.fire({
              title: this.$t('model.notifications.change_password_successfully'),
              text: '',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then(() => {
              this.changingPassword = false
              this.remindChangeRandomPasswordModal = false
            })
          }, (err) => {
            err.graphQLErrors.map(({ message }) => {
              let errorHTML = "<p>" + message + "</p>";
              Swal.close();
              Swal.fire({
                title: this.$t('model.notifications.change_password_failed'),
                icon: "error",
                html: errorHTML,
                confirmButtonText: 'OK',
              }).then(() => {
                this.changingPassword = false;
              })
            });
          })
        });
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false)
      },
      /**
       * Vuex
       */
      ...mapActions({
        clearContext: 'context/clearContext'
      }),
      switchVisibility(e) {
        //e.preventDefault()
        this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
        this.passwordFieldType1 = this.passwordFieldType1 === 'password' ? 'text' : 'password'
      },
      setSessionTimeout() {
        Helper.gqlQuery("Setting", "getAll").then(({data}) => {
          Helper.setSessionTimeout(data.session_timeout);
          // Helper.inactivityTime();
          const context = this;
          const inactivityTime = function () {
            const sessionTimeout = Helper.sessionTimeout * 60 * 1000;
            var time = setTimeout(logout, sessionTimeout);
            // window.onload = resetTimer;
            // DOM Events
            document.onmousemove = resetTimer;
            document.onkeydown = resetTimer;
            document.onclick = resetTimer; // touchpad clicks
            document.onmousedown = resetTimer; // touchscreen presses
            document.addEventListener("scroll", resetTimer, true); // improved; see comments

            function logout() {
              alert("You are now logged out.");
              context.logout();
            }

            function resetTimer() {
              clearTimeout(time);
              time = setTimeout(logout, sessionTimeout);
              // 1000 milliseconds = 1 second
            }
          };
          if (Helper.sessionTimeout) {
            inactivityTime();
          }
        });

        return null;
      },
      updateNotificationCount(count) {
        this.notificationCount = count
      },
      clearNotificationCount() {
        this.notificationCount = 0
        Helper.gqlMutate('Notification', 'markRead')
      },
    }
  }
</script>
<style scoped lang="scss">
  i {
    .ni {
      font-size: 20px !important;
    }
  }

  .vue-avatar {
    box-sizing: content-box;
  }

  .avatar.rounded-circle {
    width: 35px;
    height: 35px;
  }

  .navbar-expand .navbar-nav .nav-link {
    padding-right: 0;
  }

  .navbar-expand {
    .navbar-collapse {
      overflow: auto;

      .header_logo {
        /*width: 80%;*/
        z-index: 0;

        .h2_text {
          font-size: 25px;
          color: #707070;
          font-weight: 500;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
        }
      }

      .menu-right {
        width: auto;
        right: 15px;
        top: 10px;
        //z-index: 100;
        position: absolute;

        .btn-rotate {
          top: 5px;

          i {
            .ni {
              top: 5px;
            }
          }
        }
      }
    }
  }

  .c-shadow {
    .form-control {
      border: 1px solid #dee2e6;
    }
  }

  .g-sidenav-hidden {
    .fixed {
      position: fixed;
      top: 0px;
      left: 0;
      right: 0;
      animation: smoothScroll 1s forwards;
      z-index: 99;
    }

    @keyframes smoothScroll {
      0% {
        transform: translateY(-40px);
      }
      100% {
        transform: translateY(0px);
      }
    }

    .menu-right {
      position: fixed !important;
      z-index: 9999 !important;
    }
  }
</style>
