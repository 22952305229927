import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
const page = (path) => () =>
  import(`~/pages/${path}`).then((m) => m.default || m);

const routes = [
  { path: "/", name: "home", component: page("index.vue") },
  { path: "/demo", name: "demo.index", component: page("demo/index.vue") },
  { path: "/bao-tri", name: "bao-tri", component: page("maintain-screen.vue") },

  { path: "/dang-nhap", name: "dang-nhap", component: page("dang-nhap.vue") },
  { path: "/test", name: "test", component: page("test.vue") },
  {
    path: "/thong-bao-chi-tiet",
    name: "thong-bao-chi-tiet",
    component: page("thong-bao/chi-tiet.vue"),
  },
  {
    path: "/thong-ke-luong",
    name: "thong-ke-luong",
    component: page("thong-ke-luong/index.vue"),
    permissions: ["manage_report_payroll"],
  },
  {
    path: "/khao-sat-luong",
    name: "khao-sat-luong",
    component: page("thong-ke-luong/index.vue"),
    permissions: ["manage_report_payroll"],
  },
  {
    path: "/khach-hang",
    name: "khach_hang.index",
    component: page("khach-hang/index.vue"),
  },
  {
    path: "/yeu-cau-duyet",
    name: "yeu_cau_duyet.index",
    component: page("yeu-cau-duyet/index.vue"),
  },
  {
    path: "/khach-hang/tao-moi",
    name: "khach_hang.tao_moi",
    component: page("khach-hang/tao-moi.vue"),
  },
  {
    path: "/khach-hang/sua/:id",
    name: "khach_hang.sua",
    component: page("khach-hang/sua.vue"),
  },
  {
    path: "/khach-hang/chi-tiet/:id",
    name: "khach_hang.chi_tiet",
    component: page("khach-hang/chi-tiet.vue"),
  },
  {
    path: "/khach-hang/tong-quan/:id",
    name: "khach_hang.tong_quan",
    component: page("khach-hang/tong-quan.vue"),
  },
  {
    path: "/khach-hang/:id/dinh-nghia",
    name: "khach_hang.dinh_nghia",
    component: page("khach-hang/dinh-nghia.vue"),
  },
  {
    path: "/khach-hang/:id/thiet-lap",
    name: "khach_hang.thiet_lap",
    component: page("khach-hang/thiet-lap.vue"),
  },
  {
    path: "/khach-hang/:id/thiet-lap-yeu-cau",
    name: "khach_hang.thiet_lap_yeu_cau",
    component: page("khach-hang/thiet-lap-yeu-cau.vue"),
  },
  {
    path: "/khach-hang/:id/lich-su-luong-chuc-vu",
    name: "khach_hang.lich_su_luong_chuc_vu",
    component: page("khach-hang/lich-su-luong-chuc-vu.vue"),
  },
  {
    path: "/khach-hang/:id/import-data",
    name: "khach_hang.import_data",
    component: page("khach-hang/import-data/index.vue"),
  },
  {
    path: "/khach-hang/:id/thiet-lap-flow",
    name: "khach_hang.thiet_lap_flow",
    component: page("khach-hang/thiet-lap-flow.vue"),
  },
  {
    path: "/khach-hang/:id/thiet-lap-flow/phan-quyen",
    name: "khach_hang.thiet_lap_flow.phan_quyen",
    component: page("khach-hang/phan-quyen.vue"),
  },
  {
    path: "/khach-hang/:id/quan-ly-ngay-le",
    name: "khach_hang.quan_ly_ngay_le",
    component: page("khach-hang/quan-ly-ngay-le/index.vue"),
  },
  {
    path: "/khach-hang/:id/cham-cong",
    name: "khach_hang.cham_cong",
    component: page("khach-hang/cham-cong/index.vue"),
  },
  {
    path: "/khach-hang/:id/lich-lam-viec",
    name: "khach_hang.lich_lam_viec",
    component: page("khach-hang/lich-lam-viec/index.vue"),
  },
  {
    path: "/khach-hang/:id/template",
    name: "khach_hang.quan_ly_template",
    component: page("khach-hang/template.vue"),
  },
  {
    path: "/khach-hang/:id/lich-lam-viec/:templateId",
    name: "quan_ly_lich_lam_viec.danh_sach",
    component: page("khach-hang/lich-lam-viec/danh-sach.vue"),
  },

  {
    path: "/khach-hang/:id/mau-tinh-luong",
    name: "khach_hang.mau_tinh_luong",
    component: page("khach-hang/mau-tinh-luong/index.vue"),
  },
  {
    path: "/khach-hang/:id/mau-tinh-luong/tao-moi",
    name: "khach_hang.mau_tinh_luong.tao_moi",
    component: page("khach-hang/mau-tinh-luong/tao-moi.vue"),
  },
  {
    path: "/khach-hang/:id/mau-tinh-luong/chi-tiet",
    name: "khach_hang.mau_tinh_luong.chi_tiet",
    component: page("khach-hang/mau-tinh-luong/chi-tiet.vue"),
  },
  {
    path: "/khach-hang/:id/mau-tinh-luong/mau-payslip",
    name: "khach_hang.mau_tinh_luong.mau_payslip",
    component: page(
      "khach-hang/mau-tinh-luong/thiet-lap-thong-tin-phieu-luong-ca-nhan.vue"
    ),
  },
  {
    path: "/khach-hang/:id/mau-tinh-luong/mau-payslip-ketoan",
    name: "khach_hang.mau_tinh_luong.mau_payslip_ketoan",
    component: page("khach-hang/mau-tinh-luong/payslip-ke-toan.vue"),
  },
  {
    path: "/payslip-template-preview/:id",
    name: "khach_hang.mau_tinh_luong.payslip_template",
    component: page("khach-hang/mau-tinh-luong/payslip-template-preview.vue"),
  },
  {
    path: "/khach-hang/:id/social-security-claim",
    name: "khach_hang.social_security_claim",
    component: page("khach-hang/social-security-claim/index.vue"),
  },
  {
    path: "/khach-hang/social-security-claim/:id/chi-tiet",
    name: "khach_hang.social_security_claim.chi-tiet",
    component: page("khach-hang/social-security-claim/chi-tiet.vue"),
  },
  {
    path: "/khach-hang/social-security-claim/:id/tao-moi",
    name: "khach_hang.social_security_claim.tao-moi",
    component: page("khach-hang/social-security-claim/tao-moi.vue"),
  },
  {
    path: "/khach-hang/:id/social-security-account",
    name: "khach_hang.social_security_account",
    component: page("khach-hang/social-security-account/index.vue"),
  },
  {
    path: "/khach-hang/:id/dependent-information",
    name: "khach_hang.dependent_information",
    component: page("khach-hang/dependent-information/index.vue"),
  },
  {
    path: "/khach-hang/:id/bang-luong",
    name: "khach_hang.bang_luong",
    component: page("khach-hang/bang-luong/index.vue"),
  },
  {
    path: "/khach-hang/:id/bang-luong/tao-moi",
    name: "khach_hang.bang_luong.tao_moi",
    component: page("khach-hang/bang-luong/tao-moi.vue"),
  },
  {
    path: "/khach-hang/:id/bang-luong/tao-bang-tinh-luong-thu",
    name: "khach_hang.bang_luong.tao_bang_tinh_luong_thu",
    component: page("khach-hang/bang-luong/tao-bang-tinh-luong-thu.vue"),
  },

  {
    path: "/khach-hang/bang-luong/:id/chi-tiet",
    name: "khach_hang.bang_luong.chi_tiet",
    component: page("khach-hang/bang-luong/chi-tiet.vue"),
  },
  {
    path: "/khach-hang/bang-luong/:id/sua",
    name: "khach_hang.bang_luong.sua",
    component: page("khach-hang/bang-luong/sua.vue"),
  },
  {
    path: "/khach-hang/bang-luong/:id/export-payslip",
    name: "khach_hang.bang_luong.export_payslip",
    component: page("khach-hang/bang-luong/export-payslip.vue"),
  },
  {
    path: "/khach-hang/bang-luong/:id/export-payslip/print/",
    name: "khach_hang.bang_luong.export_payslip.print",
    component: page("khach-hang/bang-luong/export-payslip-print.vue"),
  },
  {
    path: "/khach-hang/bang-luong/export-preview-payslip",
    name: "khach_hang.bang_luong.export_preview_payslip",
    component: page("khach-hang/bang-luong/export-payslip-preview-print.vue"),
  },

  {
    path: "/khach-hang/:id/nhan-vien",
    name: "khach_hang.nhan_vien",
    component: page("khach-hang/nhan-vien/index.vue"),
  },
  {
    path: "/khach-hang/:id/nhan-vien/tao-moi",
    name: "khach_hang.nhan_vien.tao_moi",
    component: page("khach-hang/nhan-vien/tao-moi.vue"),
  },
  {
    path: "/khach-hang/nhan-vien/:id/thong-tin-co-ban/:clientType?",
    name: "khach_hang.nhan_vien.chi_tiet.thong_tin_co_ban",
    component: page("khach-hang/nhan-vien/chi-tiet/thong-tin-co-ban.vue"),
  },
  {
    path: "/khach-hang/nhan-vien/:id/thong-tin-lao-dong-nuoc-ngoai/:clientType?",
    name: "khach_hang.nhan_vien.chi_tiet.thong_tin_lao_dong_nuoc_ngoai",
    component: page(
      "khach-hang/nhan-vien/chi-tiet/thong-tin-lao-dong-nuoc-ngoai.vue"
    ),
  },
  {
    path: "/khach-hang/nhan-vien/:id/thong-tin-khac/:clientType?",
    name: "khach_hang.nhan_vien.chi_tiet.thong_tin_khac",
    component: page("khach-hang/nhan-vien/chi-tiet/thong-tin-khac.vue"),
  },
  {
    path: "/khach-hang/nhan-vien/:id/thong-tin-luong/:clientType?",
    name: "khach_hang.nhan_vien.chi_tiet.thong_tin_luong",
    component: page("khach-hang/nhan-vien/chi-tiet/thong-tin-luong.vue"),
  },
  {
    path: "/khach-hang/nhan-vien/:id/quyen-truy-cap",
    name: "khach_hang.nhan_vien.chi_tiet.quyen_truy_cap",
    component: page("khach-hang/nhan-vien/chi-tiet/quyen-truy-cap.vue"),
  },
  {
    path: "/khach-hang/nhan-vien/:id/timesheet",
    name: "khach_hang.nhan_vien.chi_tiet.timesheet",
    component: page("khach-hang/nhan-vien/chi-tiet/timesheet.vue"),
  },
  {
    path: "/khach-hang/:id/nhom-nhan-vien",
    name: "khach_hang.nhom_nhan_vien",
    component: page("khach-hang/nhan-vien/group.vue"),
  },
  {
    path: "/khach-hang/:id/bao-cao-lao-dong-nuoc-ngoai",
    name: "khach_hang.bao_cao_lao_dong_nuoc_ngoai",
    component: page("khach-hang/bao-cao-lao-dong-nuoc-ngoai/index.vue"),
  },
  {
    path: "/khach-hang/:id/bao-cao-tinh-hinh-lao-dong",
    name: "khach_hang.bao_cao_tinh_hinh_lao_dong",
    component: page("khach-hang/bao-cao-tinh-hinh-lao-dong/index.vue"),
  },
  {
    path: "/khach-hang/:id/reset-password-hang-loat",
    name: "khach_hang.reset_password_hang_loat",
    component: page("khach-hang/reset-password-hang-loat.vue"),
  },

  {
    path: "/khach-hang/:id/bao-cao-ke-toan",
    name: "khach_hang.bao_cao_ke_toan",
    component: page("khach-hang/bao-cao-ke-toan/index.vue"),
  },
  {
    path: "/khach-hang/:client_id/bao-cao-ke-toan/:id",
    name: "khach_hang.bao_cao_ke_toan.chi_tiet",
    component: page("khach-hang/bao-cao-ke-toan/chi-tiet.vue"),
  },
  {
    path: "/khach-hang/:client_id/bao-cao-ke-toan/tao-moi",
    name: "khach_hang.bao_cao_ke_toan.tao_moi",
    component: page("khach-hang/bao-cao-ke-toan/tao-moi.vue"),
  },

  {
    path: "/khach-hang/:id/mau-bao-cao",
    name: "khach_hang.mau_bao_cao",
    component: page("khach-hang/bao-cao-ke-toan/mau-bao-cao.vue"),
  },

  {
    path: "/khach-hang/:id/ke-khai-bao-hiem",
    name: "khach_hang.ke_khai_bao_hiem",
    component: page("khach-hang/ke-khai-bao-hiem/index.vue"),
  },

  {
    path: "/khach-hang/:client_id/ke-khai-bao-hiem/:id/chi-tiet",
    name: "khach_hang.ke_khai_bao_hiem.chi_tiet",
    component: page("khach-hang/ke-khai-bao-hiem/chi-tiet.vue"),
  },

  {
    path: "/khach-hang/:id/ke-khai-bao-hiem/create",
    name: "khach_hang.ke_khai_bao_hiem.tao_moi",
    component: page("khach-hang/ke-khai-bao-hiem/create.vue"),
  },

  {
    path: "/khach-hang/:id/log",
    name: "khach_hang.log",
    component: page("khach-hang/log.vue"),
  },
  {
    path: "/khach-hang/:id/nop-ho-so",
    name: "khach_hang.nop_ho_so",
    component: page("khach-hang/nop-ho-so/index.vue"),
  },
  {
    path: "/khach-hang/:client_id/nop-ho-so/:id/chi-tiet",
    name: "khach_hang.nop_ho_so.chi_tiet",
    component: page("khach-hang/nop-ho-so/chi-tiet.vue"),
  },
  {
    path: "/khach-hang/:id/nop-ho-so/tao-moi",
    name: "khach_hang.nop_ho_so.tao_moi",
    component: page("khach-hang/nop-ho-so/tao-moi.vue"),
  },
  {
    path: "/khach-hang/:id/quan-ly-hop-dong",
    name: "khach_hang.quan_ly_hop_dong",
    component: page("khach-hang/hop-dong/index.vue"),
  },
  {
    path: "/khach-hang/:id/quan-ly-hop-dong/tao-moi",
    name: "khach_hang.quan_ly_hop_dong_tao_moi",
    component: page("khach-hang/hop-dong/create.vue"),
  },
  {
    path: "/khach-hang/:id/quan-ly-mau-hop-dong",
    name: "khach_hang.quan_ly_mau_hop_dong",
    component: page("khach-hang/mau-hop-dong/index.vue"),
  },

  {
    path: "/khach-hang/:id/nhom-tro-cap",
    name: "khach_hang.nhom_tro_cap",
    component: page("khach-hang/nhom-tro-cap/index.vue"),
  },
  {
    path: "/khach-hang/:id/bao-cao-pit",
    name: "khach_hang.bao_cao_pit",
    component: page("khach-hang/bao-cao-pit/index.vue"),
  },
  {
    path: "/khach-hang/:id/tong-hop-pit",
    name: "khach_hang.bao_cao_pit.tong_hop_pit",
    component: page("khach-hang/bao-cao-pit/statistics.vue"),
  },
  {
    path: "/khach-hang/:id/bao-cao-pit/tao-moi",
    name: "khach_hang.bao_cao_pit.tao_moi",
    component: page("khach-hang/bao-cao-pit/create.vue"),
  },
  // {
  //   path: "/khach-hang/:id/cau-truc-phong-ban",
  //   name: "khach_hang.cau_truc_phong_ban",
  //   component: page("khach-hang/cau-truc-phong-ban/index.vue"),
  // },
  {
    path: "/khach-hang/:id/quan-ly-phong-ban",
    name: "khach_hang.quan_ly_phong_ban",
    component: page("khach-hang/quan-ly-phong-ban/index.vue"),
  },
  {
    path: "/khach-hang/:id/quan-ly-chuc-vu",
    name: "khach_hang.quan_ly_chuc_vu",
    component: page("khach-hang/quan-ly-chuc-vu/index.vue"),
  },
  // {
  //   path: "/khach-hang/:id/so-do-to-chuc",
  //   name: "khach_hang.so_do_to_chuc",
  //   component: page("khach-hang/cau-truc-phong-ban/so-do-to-chuc.vue"),
  // },

  {
    path: "/khach-hang/:id/quan-ly-phe-duyet",
    name: "khach_hang.quan-ly-phe-duyet",
    component: page("khach-hang/quan-ly-phe-duyet/index.vue"),
  },

  {
    path: "/khach-hang/:id/quan-ly-du-an",
    name: "khach_hang.quan_ly_du_an",
    component: page("khach-hang/quan-ly-du-an/index.vue"),
  },
  {
    path: "/khach-hang/quan-ly-du-an",
    name: "khach_hang.quan_ly_du_an_1",
    component: page("khach-hang/quan-ly-du-an/index.vue"),
  },
  {
    path: "/khach-hang/:id/quan-ly-du-an/chi-tiet/:project_id",
    name: "khach_hang.quan_ly_du_an.chi_tiet",
    component: page("khach-hang/quan-ly-du-an/chi-tiet.vue"),
  },
  {
    path: "/khach-hang/quan-ly-du-an/chi-tiet/:project_id",
    name: "khach_hang.quan_ly_du_an_1.chi_tiet",
    component: page("khach-hang/quan-ly-du-an/chi-tiet.vue"),
  },

  {
    path: "/khach-hang/:id/quan-ly-timesheet",
    name: "khach_hang.quan_ly_timesheet",
    component: page("khach-hang/quan-ly-timesheet/index.vue"),
  },
  {
    path: "/khach-hang/:id/nha-cung-cap",
    name: "khach_hang.nha_cung_cap",
    component: page("khach-hang/nha-cung-cap/index.vue"),
  },
  {
    path: "/khach-hang/:id/tra-cuu-mst",
    name: "khach_hang.tra_cuu_mst",
    component: page("khach-hang/tra-cuu-mst/index.vue"),
  },
  {
    path: "/khach-hang/:id/tra-cuu-mst/ket-qua",
    name: "khach_hang.tra_cuu_mst_ket_qua",
    component: page("khach-hang/tra-cuu-mst/result.vue"),
  },
  {
    path: "/quan-ly-danh-sach-khach-hang",
    name: "khach_hang.index",
    component: page("khach-hang/index.vue"),
  },
  {
    path: "/quan-ly-cong-thuc-chung",
    name: "cong_thuc_chung",
    component: page("quan-ly-cong-thuc/index.vue"),
  },
  {
    path: "/quan-ly-cong-thuc-chung/tao-moi",
    name: "cong_thuc_chung.tao_moi",
    component: page("quan-ly-cong-thuc/tao-moi.vue"),
  },
  {
    path: "/quan-ly-cong-thuc-chung/sua/:id",
    name: "cong_thuc_chung.sua",
    component: page("quan-ly-cong-thuc/sua.vue"),
  },
  {
    path: "/khach-hang/:id/cong-thuc",
    name: "khach_hang.cong_thuc",
    component: page("khach-hang/cong-thuc-chung/index.vue"),
  },
  {
    path: "/khach-hang/:id/cong-thuc/tao-moi",
    name: "khach_hang.cong_thuc.tao_moi",
    component: page("khach-hang/cong-thuc-chung/tao-moi.vue"),
  },
  {
    path: "/khach-hang/cong-thuc/sua/:id",
    name: "khach_hang.cong_thuc.sua",
    component: page("khach-hang/cong-thuc-chung/sua.vue"),
  },
  {
    path: "/tien-do",
    name: "tiendo.index",
    component: page("tien-do/index.vue"),
  },
  {
    path: "/thiet-lap",
    name: "thietlap.index",
    component: page("thiet-lap/thong-tin.vue"),
  },
  {
    path: "/nhan-vien",
    name: "nhanvien.index",
    component: page("nhan-vien-iglocal/index.vue"),
    permissions: ["manage_iglocal_user"],
  },
  {
    path: "/nhan-vien/tao-moi",
    name: "nhanvien.tao_moi",
    component: page("nhan-vien-iglocal/tao-moi.vue"),
    permissions: ["manage_iglocal_user"],
  },
  {
    path: "/nhan-vien/:id/chi-tiet",
    name: "nhanvien.chi-tiet",
    component: page("nhan-vien-iglocal/chi-tiet.vue"),
    permissions: ["manage_iglocal_user"],
  },

  {
    path: "/quan-ly-ke-toan",
    name: "quan_ly_ke_toan.index",
    component: page("quan-ly-ke-toan/index.vue"),
  },

  {
    path: "/yeu-cau-ho-tro",
    name: "yeu-cau-ho-tro.index",
    component: page("yeu-cau-ho-tro/index.vue"),
  },
  {
    path: "/yeu-cau-ho-tro/chi-tiet/:id",
    name: "yeu-cau-ho-tro.chitiet",
    component: page("yeu-cau-ho-tro/chi-tiet.vue"),
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: page('FAQ/index.vue'),
  },
  {
    path: '/FAQ/history',
    name: 'FAQ.history',
    component: page('FAQ/history.vue'),
  },
  {
    path: "/bao-cao-tien-do",
    name: "bao-cao-tien-do.index",
    component: page("bao-cao-tien-do/index.vue"),
  },

  {
    path: "/quan-ly-chi-ho",
    name: "quan-ly-chi-ho.index",
    component: page("quan-ly-chi-ho/index.vue"),
    permissions: ["manage_debit_request"],
  },

  {
    path: "/quan-ly-dich-vu-chi-ho",
    name: "quan-ly-dich-vu-chi-ho.index",
    component: page("quan-ly-dich-vu-chi-ho/index.vue"),
    permissions: ["manage_debit_request"],
  },

  {
    path: "/quan-ly-ghi-no",
    name: "quan-ly-ghi-no.index",
    component: page("quan-ly-ghi-no/index.vue"),
    permissions: ["manage_debit_history"],
  },
  {
    path: "/quan-ly-thiet-lap-debit",
    name: "quan-ly-thiet-lap-debit.index",
    component: page("quan-ly-thiet-lap-debit/index.vue"),
    permissions: ["manage_debit_setup"],
  },

  // TODO remove this route
  {
    path: "/them-cot-thay-doi-thu-tu",
    name: "them-cot-thay-doi-thu-tu.index",
    component: page("them-cot-thay-doi-thu-tu/index.vue"),
  },

  {
    path: "/quan-ly-khach-hang-moi",
    name: "quan-ly-khach-hang-moi.index",
    component: page("quan-ly-khach-hang-moi/index.vue"),
    permissions: ["manage_clients"],
  },

  {
    path: "/quan-ly-ngan-hang",
    name: "quan-ly-ngan-hang.index",
    component: page("quan-ly-ngan-hang/index.vue"),
    permissions: ["manage_clients"],
  },

  {
    path: "/quan-ly-benh-vien",
    name: "quan-ly-benh-vien.index",
    component: page("quan-ly-benh-vien/index.vue"),
    permissions: ["manage_clients"],
  },

  {
    path: "/thu-vien-cau-hoi",
    name: "thu-vien-cau-hoi",
    component: page("hoi-dap/index.vue"),
  },
  {
    path: '/thu-vien-cau-hoi/chi-tiet',
    name: 'thu-vien-cau-hoi-chi-tiet',
    component: page('hoi-dap/detail.vue'),
  },
  {
    path: '/thu-vien-cau-hoi/chi-tiet',
    name: 'thu-vien-cau-hoi-chi-tiet',
    component: page('hoi-dap/detail.vue'),
  },
  {
    path: "/quan-ly-thu-vien-cau-hoi",
    name: "quan-ly-thu-vien-cau-hoi",
    component: page("hoi-dap/index.vue"),
  },
  {
    path: "/quan-ly-thu-vien-cau-hoi/chi-tiet",
    name: "thu-vien-quan-ly-cau-hoi-chi-tiet",
    component: page("hoi-dap/detail.vue"),
  },
  {
    path: "/ngay-nghi-le",
    name: "ngay-nghi-le",
    component: page("ngay-nghi-le/index.vue"),
  },
  {
    path: "/quan-ly-template",
    name: "quan-ly-template",
    component: page("quan-ly-template/index.vue"),
    permissions: ["manage_export_template"],
  },
  {
    path: "/quan-ly-thu-muc",
    name: "quan-ly-thu-muc",
    component: page("quan-ly-thu-muc/index.vue"),
    permissions: [],
  },
  {
    path: "/translation",
    name: "translation",
    component: page("translation/index.vue"),
  },
  {
    path: "/quan-ly-email-template",
    name: "email-template",
    component: page("email-template/index.vue"),
    permissions: ["manage_clients"],
  },
  {
    path: "/quan-ly-email-template/chi-tiet/:id",
    name: "email-template.chitiet",
    component: page("email-template/chi-tiet.vue"),
    permissions: ["manage_clients"],
  },
  {
    path: "/quan-ly-phan-quyen",
    name: "quan-ly-phan-quyen",
    component: page("quan-ly-phan-quyen/index.vue"),
    permissions: ["manage_iglocal_user"],
  },
  {
    path: "/action-log",
    name: "action-log",
    component: page("action-log/index.vue"),
    permissions: [],
  },
  {
    path: "/mobile-version",
    name: "mobile_version",
    component: page("mobile-version/index.vue"),
    permissions: ["manage_version_mobile"],
  },
  {
    path: "/mobile-version/tao-moi",
    name: "mobile_version.tao-moi",
    component: page("mobile-version/tao-moi.vue"),
    permissions: ["manage_version_mobile"],
  },
  {
    path: "/mobile-version/sua/:id",
    name: "mobile_version.sua",
    component: page("mobile-version/sua.vue"),
    permissions: ["manage_version_mobile"],
  },
  {
    path: "/slider",
    name: "slider",
    component: page("slider/index.vue"),
    permissions: ["manage_version_mobile"],
  },
  {
    path: "/slider/tao-moi",
    name: "slider.tao-moi",
    component: page("slider/tao-moi.vue"),
    permissions: ["manage_version_mobile"],
  },
  {
    path: "/slider/sua/:id",
    name: "slider.sua",
    component: page("slider/sua.vue"),
    permissions: ["manage_version_mobile"],
  },
  {
    path: "/setting",
    name: "Setting",
    component: page("setting/index.vue"),
    permissions: ["manage_settings"],
  },
  {
    path: "/mobile-setting",
    name: "Mobile Setting",
    component: page("mobile-setting/index.vue"),
    permissions: ["manage_settings"],
  },
  {
    path: "/quan-ly-cong-thuc",
    name: "khach_hang.quan_ly_cong_thuc",
    component: page("khach-hang/quan-ly-cong-thuc/index.vue"),
  },
  {
    path: "/quan-ly-cong-thuc/tao-moi",
    name: "khach_hang.quan_ly_cong_thuc.tao_moi",
    component: page("khach-hang/quan-ly-cong-thuc/tao-moi.vue"),
  },
  {
    path: "/quan-ly-cong-thuc/sua/:id",
    name: "khach_hang.quan-ly-cong-thuc.sua",
    component: page("khach-hang/quan-ly-cong-thuc/sua.vue"),
  },
  {
    path: "/thong-ke-giai-doan-tinh-luong",
    name: "headcount-period",
    component: page("headcount-period/index.vue"),
  },
  {
    path: "/khach-hang/thong-ke-giai-doan-tinh-luong",
    name: "headcount-period-client",
    component: page("headcount-period/index.vue"),
  },
  {
    path: "/web-version",
    name: "web_version",
    permissions: ["manage_version_mobile"],
    component: page("web-version/index.vue"),
  },
  {
    path: "/web-slider/:webVersionId",
    name: "web-slider",
    permissions: ["manage_version_mobile"],
    component: page("web-version/web-sliders/index.vue"),
  },
  {
    path: "/web-slider/create/:webVersionId",
    name: "web-slider.create",
    permissions: ["manage_version_mobile"],
    component: page("web-version/web-sliders/create.vue"),
  },
  {
    path: "/web-slider/update/:id",
    name: "web-slider.update",
    permissions: ["manage_version_mobile"],
    component: page("web-version/web-sliders/update.vue"),
  },
];

export function getRoutePermissions(routeName) {
  const r = routes.find((v) => v.name === routeName);
  if (r && r.permissions) {
    return r.permissions;
  }
  return [];
}

export function createRouter() {
  const router = new Router({
    routes,
    mode: "history",
  });
  /**
   * Raygun tracking
   */
  router.afterEach((to, from) => {
    if (typeof rg4js == "function") {
      rg4js("trackEvent", {
        type: "pageView",
        path: to.path,
      });
    }
  });
  return router;
}
