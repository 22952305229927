import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faXmark,
  faGripDotsVertical,
  faPlusLarge,
  faMinusLarge,
} from '@fortawesome/pro-regular-svg-icons'
// import { faCaretUp } from '@fortawesome/fontawesome-free'
import {
  faPenNib,
  faInfoCircle,
  faBusinessTime,
  faBadgeDollar,
  faFileCertificate,
  faMoneyCheckEditAlt,
  faClipboardCheck,
  faArrowsRepeat,
  faUsers,
  faDollarSign,
  faChartPie,
  faUserPlus,
  faDiagramProject,
  faMoneyCheckDollarPen,
  faListCheck,
  faUserLock,
  faTasks,
  faGear,
  faGears,
  faFlask,
  faKey,
  faSquareQuestion,
  faBooks,
  faUniversalAccess,
  faBellSlash,
  faAlarmPlus,
  faHouseLeave,
  faFileAlt,
  faHospitals,
  faPeopleArrows,
  faPeopleGroup,
  faAnalytics,
  faChartBar,
  faSignIn,
  faMoneyBill1Wave,
  faEnvelopeOpenDollar,
  faFileMedical,
  faFolders,
  faFileSignature,
  faUserCog,
  faChartNetwork,
  faWifi,
  faCctv,
  faBallotCheck,
  faMoneyCheck,
  faCalendarAlt,
  faCalendarStar,
  faCalendarDay,
  faFileSpreadsheet,
  faLightbulbOn,
  faText,
  faPencilRuler,
  faFilePdf,
  faFileExcel,
  faEye,
  faEyeSlash,
  faTrash,
  faBackward,
  faCloudDownloadAlt,
  faCopy,
  faUser,
  faCircleQuestion,
  faPersonCircleCheck,
  faCircleCheck,
  faChevronDown,
  faPaperPlane,
  faPeopleRoof,
  faPen,
  faCheck,
  faRefresh,
  faRotate,
  faClockRotateLeft,
  faAddressBook,
  faLaptopCode,
  faTableList,
  faBan,
  faSpinner,
  faSitemap,
  faCode,
  faChartPyramid,
  faLanguage,
  faChartLine,
  faBuildingColumns,
  faBuilding,
  faFilePen,
  faFileLines,
  faUpload,
  faHandHoldingSeedling,
  faCartFlatbedBoxes,
  faFileContract,
  faCalendarLinesPen,
  faMemoCircleCheck,
  faUserHelmetSafety,
  faChartMixed,
  faFileInvoiceDollar,
  faMemoCircleInfo,
  faClipboardList,
  faRepeat1,
  faTruckField,
  faFolderGear,
  faCalendarClock,
  faHandsHoldingDollar,
  faMoneyBillTrendUp,
  faUserCheck,
  faOctagonCheck,
  faCircleXmark,
  faUsersMedical,
  faEllipsisVertical,
  faEllipsis,
  faPencil,
  faPrint,
  faFloppyDisk,
  faPaperclip,
  faPlus,
  faRectangleList,
  faFileExport,
  faFileImport,
  faFiles,
  faMinus,
  faAngleRight,
  faAngleLeft,
  faArrowRight,
  faGrip,
  faTimer,
  faFilter,
  faBell,
  faPaste,
  faMagnifyingGlass,
} from '@fortawesome/pro-light-svg-icons'

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(
  faXmark,
  faPenNib,
  faGripDotsVertical,
  faPlusLarge,
  faMinusLarge,
  faInfoCircle,
  faBusinessTime,
  faBadgeDollar,
  faFileCertificate,
  faMoneyCheckEditAlt,
  faClipboardCheck,
  faArrowsRepeat,
  faUsers,
  faDollarSign,
  faChartPie,
  faUserPlus,
  faDiagramProject,
  faMoneyCheckDollarPen,
  faListCheck,
  faTasks,
  faUserLock,
  faGear,
  faGears,
  faFlask,
  faKey,
  faSquareQuestion,
  faBooks,
  faUniversalAccess,
  faBellSlash,
  faAlarmPlus,
  faHouseLeave,
  faFileAlt,
  faHospitals,
  faPeopleArrows,
  faPeopleGroup,
  faAnalytics,
  faChartBar,
  faSignIn,
  faMoneyBill1Wave,
  faEnvelopeOpenDollar,
  faFileMedical,
  faFolders,
  faFileSignature,
  faUserCog,
  faChartNetwork,
  faWifi,
  faCctv,
  faBallotCheck,
  faCalendarAlt,
  faCalendarStar,
  faCalendarDay,
  faFileSpreadsheet,
  faLightbulbOn,
  faText,
  faPencilRuler,
  faMoneyCheck,
  faFilePdf,
  faFileExcel,
  faEye,
  faEyeSlash,
  faTrash,
  faBackward,
  faCloudDownloadAlt,
  faCopy,
  faUser,
  faCircleQuestion,
  faPersonCircleCheck,
  faCircleCheck,
  faChevronDown,
  faPaperPlane,
  faPeopleRoof,
  faPen,
  faCheck,
  faRefresh,
  faRotate,
  faClockRotateLeft,
  faAddressBook,
  faLaptopCode,
  faTableList,
  faBan,
  faSpinner,
  faSitemap,
  faCode,
  faChartPyramid,
  faLanguage,
  faChartLine,
  faBuildingColumns,
  faBuilding,
  faFilePen,
  faFileLines,
  faUpload,
  faHandHoldingSeedling,
  faCartFlatbedBoxes,
  faFileContract,
  faCalendarLinesPen,
  faMemoCircleCheck,
  faUserHelmetSafety,
  faChartMixed,
  faFileInvoiceDollar,
  faMemoCircleInfo,
  faClipboardList,
  faRepeat1,
  faTruckField,
  faCalendarClock,
  faFolderGear,
  faHandsHoldingDollar,
  faMoneyBillTrendUp,
  faUserCheck,
  faOctagonCheck,
  faCircleXmark,
  faUsersMedical,
  faEllipsisVertical,
  faEllipsis,
  faPencil,
  faPrint,
  faPaperclip,
  faFloppyDisk,
  faPlus,
  faRectangleList,
  faFileExport,
  faFileImport,
  faFiles,
  faMinus,
  faAngleRight,
  faAngleLeft,
  faArrowRight,
  faGrip,
  faTimer,
  faUpload,
  faFilter,
  faBell,
  faPaste,
  faMagnifyingGlass,
)

// Register the component globally
Vue.component('font-awesome-icon', FontAwesomeIcon)
